/* Rajdhani */
/* latin-ext */

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/LDIxapCSOBg7S-QT7p4JM-aUWA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/LDIxapCSOBg7S-QT7p4HM-Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/LDI2apCSOBg7S-QT7pa8FvOleef2kg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/LDI2apCSOBg7S-QT7pa8FvOreec.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Tajawal */
/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/Iurf6YBj_oCad4k1l_6gHrRpiYlJ.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Iurf6YBj_oCad4k1l5qjHrRpiYlJ.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Iura6YBj_oCad4k1nzSBC45I.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Iurf6YBj_oCad4k1l8KiHrRpiYlJ.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Iurf6YBj_oCad4k1l4qkHrRpiYlJ.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Iurf6YBj_oCad4k1l5anHrRpiYlJ.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */

@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/Iurf6YBj_oCad4k1l7KmHrRpiYlJ.woff2) format("woff2");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Rajdhani", "Tajawal", sans-serif;
  overflow: overlay;
  scroll-behavior: smooth;
}

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  max-width: 90rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

:root {
  --sol-accent-color: #f2a20c;
  --sol-primary-color: #0b2e5c;
  --sol-semi-dark: #1f1f1f;
}

.bg-accent {
  background-color: var(--sol-accent-color);
}

.bg-primary {
  background-color: var(--sol-primary-color);
}

.contactpagestyle {
  min-height: calc(100vh - 424px);
}

.paddingcontact {
  padding: 100px;
}

.text-primary {
  color: var(--sol-primary-color);
}

.text-accent {
  color: var(--sol-accent-color);
}

.hover\:text-accent:hover {
  color: var(--sol-accent-color);
}

.title-separator {
  border-color: var(--sol-accent-color);
  border-width: 2px;
  width: 200px;
  margin-bottom: 10px;
  display: inline-block;
}

/* custom scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scroll {
  overflow: hidden;
  padding-right: 10px;
}

.sol-header {
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-duration: 1000ms;
}

.sol-header-menu {
  right: min(-350px, -20%);
  transition-timing-function: ease-in-out;
  width: 20%;
  min-width: 350px;
}

.sol-header-menu-primary {
  transition-duration: 400ms;
  box-shadow: -5px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.sol-header-menu-secondary {
  transition-duration: 600ms;
}

.sol-header-menu-main {
  transition-duration: 700ms;
  background: linear-gradient(180deg, #06142f 0%, #06142f 100%);
  background: linear-gradient(180deg, #0b2e5c 0%, #06142f 100%);
}

.scroll-down .sol-header {
  transform: translate3d(0, -150px, 0);
}

.sol-header-column {
  width: 200px;
  height: 80px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #000000b3;
}

.sol-header-logo {
  height: 100%;
}

.sol-header-menu-image {
  height: 20px;
}

.sol-header-menu-item {
  padding-left: 10px;
  transition: ease-in-out 0.3s;
}

.sol-header-menu-item::before {
  content: "";
  width: 35px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: -30px;
  top: 16px;
  transition: ease-in-out 0.3s;
}

.sol-header-menu-item:hover {
  width: 45px;
  color: var(--sol-accent-color);
}

.sol-header-menu-item:hover::before {
  width: 45px;
  background-color: var(--sol-accent-color);
}

.no-scroll .sol-header-menu {
  right: 0;
}
.sol-footer {
  background-image: url(../img/footer-bg.webp);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
}

.sol-secondary-footer {
  background-color: var(--sol-semi-dark);
}

.sol-secondary-footer li:not(:last-child) {
  margin-right: 5px;
  padding-right: 10px;
  border-right: 1px solid #fff;
  padding-top: 0;
  line-height: 1rem;
}

.contact-page-container {
  background-image: url(../img/footer-bg.webp), url(../img/contact-bg-black.svg);
  background-position: top left, center center;
  background-size: 500px, cover;
  background-repeat: no-repeat;
}

.buttonstyle {
  border: none;
  color: #fff;
  text-align: center;
  width: 220px;
  transition: all 0.5s;
  cursor: pointer;
}

a.bg-primary,
button.bg-primary {
  transition: all 0.4s;
}
a.bg-primary:hover,
button.bg-primary:hover {
  background-color: var(--sol-accent-color);
  background-color: #ce8807;
  opacity: 0.9;
  transform: translate(0px, 4px);
}
.grecaptcha-badge {
  visibility: hidden !important;
}
.sol-hero-section {
  background-image: url("../img/hero2.webp"), url("../img/hero1.webp"),
    linear-gradient(220deg, #0b2e5c 0%, #06142f 100%);
  background-position: right bottom, left bottom;
  background-size: calc(5rem + 30%), calc(5rem + 30%), 100%;
  background-repeat: no-repeat, no-repeat;
}

/* Contact Page */

.sol-contact-section {
  background-image: url("../img/contactusv1.webp");
  background-position: center center;
  background-size: cover;
}

.item-contact {
  background-image: url("../img/logobackground.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 250px;
}

input.heighttext {
  padding: 25px 10px;
  line-height: 50px;
}

.contact-item {
  transition: all 0.3s ease-in-out;
}

.contact-item:hover {
  transform: translate(2px, 0);
}

.contact-item img {
  transition: all 0.3s ease-in-out;
  filter: grayscale(1);
  opacity: 0.7;
}

.contact-item:hover img {
  filter: none;
}

.social-media-section > a > img {
  filter: grayscale();
  transition: all 0.3s ease-in-out;
}

.social-media-section > a > img:hover {
  transform: translate(0, -5px);
  filter: none;
}

/* Careers Styles */

.sol-careers-section {
  background-image: url("../img/careers.webp");
  background-position: center center;
  background-size: cover;
}
.sol-careers-section > div {
  background-color: #06142fa2;
}

/* Services Styles */

.sol-service-image-container {
  width: 250px;
  height: 250px;
}

.sol-service-image {
  width: 10rem;
  height: 10rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.service-container:hover .sol-service-image {
  transform: rotate(45deg);
}

.service-container:hover .sol-service-image .a {
  fill: var(--sol-accent-color);
}

.service-container:hover .sol-service-image .b {
  fill: var(--sol-accent-color);
  opacity: 0.7;
}

.sol-service-image .a {
  fill: #0b2e5c;
  transition: all 0.3s ease-in-out;
}

.sol-service-image .b {
  fill: #20406a;
  transition: all 0.3s ease-in-out;
}

.sol-service-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  transition: all 0.3s ease-in-out;
}

.service-container:hover .sol-service-icon {
  width: 80px;
}

.sol-partners img {
  max-width: 150px;
  height: 150px;
  margin: 15px 0;
  filter: grayscale(1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.sol-partners img:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

.mid-section {
  min-height: 600px;
  position: relative;
}

.mid-section img {
  position: absolute;
  right: 0;
  top: calc(400px - 30vw);
  width: 35%;
}

@media screen and (max-width: 991px) {
  .sol-header-menu {
    width: 50%;
    right: min(-350px, -50%);
  }
  .mid-section img {
    position: absolute;
    right: 0;
    top: -135px;
    width: 34%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .sol-header-column {
    width: 100px;
  }

  .contact-page-container {
    background: none;
    padding-top: 135px;
    padding-bottom: 135px;
  }
}

@media screen and (max-width: 640px) {
  .sol-header-menu {
    width: 100%;
    right: min(-350px, -100%);
  }
}

@media screen and (max-height: 620px) {
  .contact-page-container {
    padding-top: 100px;
  }

  .contactpagestyle {
    background-color: white;
  }
}

/* Hero text animation */
/* iti customizations */

.iti__flag-container {
  padding: 0px;
}

input,
.iti--allow-dropdown input,
.iti--allow-dropdown input[type="text"],
.iti--allow-dropdown input[type="tel"],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type="text"],
.iti--separate-dial-code input[type="tel"] {
  box-shadow: 3px 3px #0b2e5c;
  border-radius: 0;
  border-color: #0b2e5c;
  transition-duration: 400ms;
}

input:focus,
.iti--allow-dropdown input:focus,
.iti--allow-dropdown input[type="text"]:focus,
.iti--allow-dropdown input[type="tel"]:focus,
.iti--separate-dial-code input:focus,
.iti--separate-dial-code input[type="text"]:focus,
.iti--separate-dial-code input[type="tel"]:focus {
  box-shadow: 5px 5px #f2a20c;
  border-color: #f2a20c !important;
}

input:focus-visible,
.iti--allow-dropdown input:focus-visible,
.iti--allow-dropdown input[type="text"]:focus-visible,
.iti--allow-dropdown input[type="tel"]:focus-visible,
.iti--separate-dial-code input:focus-visible,
.iti--separate-dial-code input[type="text"]:focus-visible,
.iti--separate-dial-code input[type="tel"]:focus-visible {
  outline: none;
}

.iti--separate-dial-code .iti__selected-flag {
  color: #ffffff;
  background-color: #0b2e5c;
  transition-duration: 400ms;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #f2a20c;
}

.iti__arrow {
  border-top-color: #ffffff;
}

.iti__arrow.iti__arrow--up {
  border-top-color: transparent;
  border-bottom-color: #ffffff;
}

.hide {
  display: none;
}

/* Portfolio Customizations */

.p-item {
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-item img,
.p-item video {
  perspective: 1000px;
  transition: transform 0.7s ease-in-out;
}

.p-item img:hover,
.p-item video:hover {
  cursor: pointer;
  transform: rotate3d(5, 5, 5, 2deg) scale3d(1.1, 1.1, 1.1);
}

@media (min-width: 1440px) {
  .p-item > picture > img,
  .p-item > picture > video {
    min-height: 448px;
  }
}

article h2 {
  font-size: 2rem;
  color: #0b2e5c;
  line-height: 2rem;
  margin: 2rem 0 1rem 0;
  font-weight: 700;
}

.animated-title > div {
  overflow: hidden;
  vertical-align: top;
}

.our-clients img {
  object-fit: contain;
}

.container-mask {
  position: relative;
  /* width: 600px;
      height: 600px; */
}

.container-mask img {
  clip-path: url("#svgPath");
}

.rotate {
  animation: rotation 52s infinite linear;
  transform-origin: 50% 50%;
}

.rotate2 {
  transform-origin: 50% 50%;
  animation: rotation2 48s infinite linear;
}

.background-devices {
  background-image: url(../img/background.svg);
  background-repeat: no-repeat;
  overflow: hidden;
}

.about-us-hero-section {
  position: absolute;
  margin-top: -50px;
  right: 50%;
  transform: translate(50%, 0);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotation2 {
  from {
    transform: translate(10px, 10px) rotate(0deg);
  }

  to {
    transform: translate(10px, 10px) rotate(360deg);
  }
}

.theSvg clipPath {
  transition: transform 0.5s;
  transform-origin: 50% 50%;
}

.theSvg:hover clipPath {
  transform: rotate(45deg);
}

.theSvg rect {
  fill: #06142f8e;
  mix-blend-mode: color;
  transition: all 0.5s ease-in-out;
}

.theSvg:hover rect {
  fill: transparent;
}

.background-years {
  background: linear-gradient(
    0deg,
    rgba(29, 48, 86, 1) 0%,
    rgba(217, 222, 232, 1) 100%
  );
  background-repeat: no-repeat;
  background-size: 10px 88%;
  background-clip: content-box;
  background-position: top;
}

.background-years > div {
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: 120px 120px;
}

.background-years h2 {
  position: relative;
}

.background-years p {
  background-color: white;
}

.background-years > div > div + div {
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  font-weight: bold;
  font-size: 2rem;
  transform: translate(-50%);
  width: 80px;
  height: 80px;
}

.background-years > div:nth-child(odd) > div > h2:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  right: -9px;
  top: 28px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #f2a20c;
}

.background-years > div:nth-child(even) > div > h2:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -9px;
  top: 30px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #06142f;
}

.background-years > div:nth-child(odd) > div + div,
.background-years > div:nth-child(odd) > div > h2 {
  background-color: #f2a20c;
}

.background-years > div:nth-child(even) > div + div,
.background-years > div:nth-child(even) > div > h2 {
  background-color: #06142f;
}

@media (max-width: 1024px) {
  .background-years {
    background-position: 10% 0%;
    background-size: 10px 85%;
  }

  .background-years > div > div + div {
    left: 11%;
    font-size: 1.5rem;
    width: 70px;
    height: 70px;
  }

  .background-years > div > div > h2:after {
    left: -18px;
    right: initial;
    top: 21px !important;
  }

  .background-years > div:nth-child(odd) > div > h2:after {
    border-left: 10px solid transparent;
    border-right: 10px solid #f2a20c;
  }
}

.main {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 0.5px;
}

.section {
  transform-style: preserve-3d;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.no-parallax {
  background-color: #111;
  z-index: 999;
}

section h1 {
  text-align: center;
  font-size: 4rem;
  font-family: sans-serif;
}

.parallax h1 {
  width: 60%;
  font-size: 2rem;
}

.parallax::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size: 100%;
  z-index: -1;
}

.bg::after {
  background: url("https://picsum.photos/1080/720");
  background-size: cover;
}
