@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("LDIxapCSOBg7S-QT7p4JM-aUWA.0db08b6e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("LDIxapCSOBg7S-QT7p4HM-Y.b193acae.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("LDI2apCSOBg7S-QT7pa8FvOleef2kg.8b4a9655.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("LDI2apCSOBg7S-QT7pa8FvOreec.68460d4f.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Iurf6YBj_oCad4k1l_6gHrRpiYlJ.5b494b05.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Iurf6YBj_oCad4k1l5qjHrRpiYlJ.c5665e7a.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Iura6YBj_oCad4k1nzSBC45I.b9e437fc.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Iurf6YBj_oCad4k1l8KiHrRpiYlJ.a0e2a405.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Iurf6YBj_oCad4k1l4qkHrRpiYlJ.cf070546.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Iurf6YBj_oCad4k1l5anHrRpiYlJ.71a34d9b.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: Tajawal;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Iurf6YBj_oCad4k1l7KmHrRpiYlJ.6389a2b4.woff2") format("woff2");
  unicode-range: U+6??, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow: overlay;
  scroll-behavior: smooth;
  font-family: Rajdhani, Tajawal, sans-serif;
}

.container, .container-fluid {
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  max-width: 90rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

:root {
  --sol-accent-color: #f2a20c;
  --sol-primary-color: #0b2e5c;
  --sol-semi-dark: #1f1f1f;
}

.bg-accent {
  background-color: var(--sol-accent-color);
}

.bg-primary {
  background-color: var(--sol-primary-color);
}

.contactpagestyle {
  min-height: calc(100vh - 424px);
}

.paddingcontact {
  padding: 100px;
}

.text-primary {
  color: var(--sol-primary-color);
}

.text-accent, .hover\:text-accent:hover {
  color: var(--sol-accent-color);
}

.title-separator {
  border-color: var(--sol-accent-color);
  border-width: 2px;
  width: 200px;
  margin-bottom: 10px;
  display: inline-block;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scroll {
  padding-right: 10px;
  overflow: hidden;
}

.sol-header {
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.77, 0, .175, 1);
}

.sol-header-menu {
  width: 20%;
  min-width: 350px;
  transition-timing-function: ease-in-out;
  right: min(-350px, -20%);
}

.sol-header-menu-primary {
  transition-duration: .4s;
  box-shadow: -5px 5px 15px #0003;
}

.sol-header-menu-secondary {
  transition-duration: .6s;
}

.sol-header-menu-main {
  background: linear-gradient(#0b2e5c 0%, #06142f 100%);
  transition-duration: .7s;
}

.scroll-down .sol-header {
  transform: translate3d(0, -150px, 0);
}

.sol-header-column {
  background-color: #000000b3;
  width: 200px;
  height: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

.sol-header-logo {
  height: 100%;
}

.sol-header-menu-image {
  height: 20px;
}

.sol-header-menu-item {
  padding-left: 10px;
  transition: all .3s ease-in-out;
}

.sol-header-menu-item:before {
  content: "";
  background-color: #fff9;
  width: 35px;
  height: 3px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 16px;
  left: -30px;
}

.sol-header-menu-item:hover {
  color: var(--sol-accent-color);
  width: 45px;
}

.sol-header-menu-item:hover:before {
  background-color: var(--sol-accent-color);
  width: 45px;
}

.no-scroll .sol-header-menu {
  right: 0;
}

.sol-footer {
  background-image: url("footer-bg.0e34bf7d.webp");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.sol-secondary-footer {
  background-color: var(--sol-semi-dark);
}

.sol-secondary-footer li:not(:last-child) {
  border-right: 1px solid #fff;
  margin-right: 5px;
  padding-top: 0;
  padding-right: 10px;
  line-height: 1rem;
}

.contact-page-container {
  background-image: url("footer-bg.0e34bf7d.webp"), url("contact-bg-black.514cd779.svg");
  background-position: 0 0, center;
  background-repeat: no-repeat;
  background-size: 500px, cover;
}

.buttonstyle {
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  width: 220px;
  transition: all .5s;
}

a.bg-primary, button.bg-primary {
  transition: all .4s;
}

a.bg-primary:hover, button.bg-primary:hover {
  background-color: var(--sol-accent-color);
  opacity: .9;
  background-color: #ce8807;
  transform: translate(0, 4px);
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.sol-hero-section {
  background-image: url("hero2.8613e8fd.webp"), url("hero1.d35c6629.webp"), linear-gradient(220deg, #0b2e5c 0%, #06142f 100%);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat, no-repeat;
  background-size: calc(5rem + 30%), calc(5rem + 30%), 100%;
}

.sol-contact-section {
  background-image: url("contactusv1.e473571d.webp");
  background-position: center;
  background-size: cover;
}

.item-contact {
  background-image: url("logobackground.c16d2327.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 250px;
}

input.heighttext {
  padding: 25px 10px;
  line-height: 50px;
}

.contact-item {
  transition: all .3s ease-in-out;
}

.contact-item:hover {
  transform: translate(2px);
}

.contact-item img {
  filter: grayscale();
  opacity: .7;
  transition: all .3s ease-in-out;
}

.contact-item:hover img {
  filter: none;
}

.social-media-section > a > img {
  filter: grayscale();
  transition: all .3s ease-in-out;
}

.social-media-section > a > img:hover {
  filter: none;
  transform: translate(0, -5px);
}

.sol-careers-section {
  background-image: url("careers.ced0806c.webp");
  background-position: center;
  background-size: cover;
}

.sol-careers-section > div {
  background-color: #06142fa2;
}

.sol-service-image-container {
  width: 250px;
  height: 250px;
}

.sol-service-image {
  cursor: pointer;
  width: 10rem;
  height: 10rem;
  transition: all .3s ease-in-out;
}

.service-container:hover .sol-service-image {
  transform: rotate(45deg);
}

.service-container:hover .sol-service-image .a {
  fill: var(--sol-accent-color);
}

.service-container:hover .sol-service-image .b {
  fill: var(--sol-accent-color);
  opacity: .7;
}

.sol-service-image .a {
  fill: #0b2e5c;
  transition: all .3s ease-in-out;
}

.sol-service-image .b {
  fill: #20406a;
  transition: all .3s ease-in-out;
}

.sol-service-icon {
  width: 75px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-container:hover .sol-service-icon {
  width: 80px;
}

.sol-partners img {
  filter: grayscale();
  cursor: pointer;
  max-width: 150px;
  height: 150px;
  margin: 15px 0;
  transition: all .5s ease-in-out;
}

.sol-partners img:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

.mid-section {
  min-height: 600px;
  position: relative;
}

.mid-section img {
  width: 35%;
  position: absolute;
  top: calc(400px - 30vw);
  right: 0;
}

@media screen and (width <= 991px) {
  .sol-header-menu {
    width: 50%;
    right: min(-350px, -50%);
  }

  .mid-section img {
    width: 34%;
    position: absolute;
    top: -135px;
    right: 0;
  }
}

@media screen and (width <= 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sol-header-column {
    width: 100px;
  }

  .contact-page-container {
    background: none;
    padding-top: 135px;
    padding-bottom: 135px;
  }
}

@media screen and (width <= 640px) {
  .sol-header-menu {
    width: 100%;
    right: min(-350px, -100%);
  }
}

@media screen and (height <= 620px) {
  .contact-page-container {
    padding-top: 100px;
  }

  .contactpagestyle {
    background-color: #fff;
  }
}

.iti__flag-container {
  padding: 0;
}

input, .iti--allow-dropdown input, .iti--allow-dropdown input[type="text"], .iti--allow-dropdown input[type="tel"], .iti--separate-dial-code input, .iti--separate-dial-code input[type="text"], .iti--separate-dial-code input[type="tel"] {
  border-color: #0b2e5c;
  border-radius: 0;
  transition-duration: .4s;
  box-shadow: 3px 3px #0b2e5c;
}

input:focus, .iti--allow-dropdown input:focus, .iti--allow-dropdown input[type="text"]:focus, .iti--allow-dropdown input[type="tel"]:focus, .iti--separate-dial-code input:focus, .iti--separate-dial-code input[type="text"]:focus, .iti--separate-dial-code input[type="tel"]:focus {
  box-shadow: 5px 5px #f2a20c;
  border-color: #f2a20c !important;
}

input:focus-visible, .iti--allow-dropdown input:focus-visible, .iti--allow-dropdown input[type="text"]:focus-visible, .iti--allow-dropdown input[type="tel"]:focus-visible, .iti--separate-dial-code input:focus-visible, .iti--separate-dial-code input[type="text"]:focus-visible, .iti--separate-dial-code input[type="tel"]:focus-visible {
  outline: none;
}

.iti--separate-dial-code .iti__selected-flag {
  color: #fff;
  background-color: #0b2e5c;
  transition-duration: .4s;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #f2a20c;
}

.iti__arrow {
  border-top-color: #fff;
}

.iti__arrow.iti__arrow--up {
  border-top-color: #0000;
  border-bottom-color: #fff;
}

.hide {
  display: none;
}

.p-item {
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: hidden;
}

.p-item img, .p-item video {
  perspective: 1000px;
  transition: transform .7s ease-in-out;
}

.p-item img:hover, .p-item video:hover {
  cursor: pointer;
  transform: rotate3d(5, 5, 5, 2deg)scale3d(1.1, 1.1, 1.1);
}

@media (width >= 1440px) {
  .p-item > picture > img, .p-item > picture > video {
    min-height: 448px;
  }
}

article h2 {
  color: #0b2e5c;
  margin: 2rem 0 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
}

.animated-title > div {
  vertical-align: top;
  overflow: hidden;
}

.our-clients img {
  object-fit: contain;
}

.container-mask {
  position: relative;
}

.container-mask img {
  clip-path: url("#svgPath");
}

.rotate {
  transform-origin: 50%;
  animation: 52s linear infinite rotation;
}

.rotate2 {
  transform-origin: 50%;
  animation: 48s linear infinite rotation2;
}

.background-devices {
  background-image: url("background.a95f9525.svg");
  background-repeat: no-repeat;
  overflow: hidden;
}

.about-us-hero-section {
  margin-top: -50px;
  position: absolute;
  right: 50%;
  transform: translate(50%);
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotation2 {
  from {
    transform: translate(10px, 10px)rotate(0);
  }

  to {
    transform: translate(10px, 10px)rotate(360deg);
  }
}

.theSvg clipPath {
  transform-origin: 50%;
  transition: transform .5s;
}

.theSvg:hover clipPath {
  transform: rotate(45deg);
}

.theSvg rect {
  fill: #06142f8e;
  mix-blend-mode: color;
  transition: all .5s ease-in-out;
}

.theSvg:hover rect {
  fill: #0000;
}

.background-years {
  background: linear-gradient(0deg, #1d3056 0%, #d9dee8 100%) top / 10px 88% no-repeat padding-box content-box;
}

.background-years > div {
  background-repeat: no-repeat;
  background-size: 120px 120px;
  margin-top: 50px;
}

.background-years h2 {
  position: relative;
}

.background-years p {
  background-color: #fff;
}

.background-years > div > div + div {
  color: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 10px;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.background-years > div:nth-child(odd) > div > h2:after {
  content: " ";
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 10px solid #f2a20c;
  width: 0;
  height: 0;
  position: absolute;
  top: 28px;
  right: -9px;
}

.background-years > div:nth-child(2n) > div > h2:after {
  content: " ";
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-right: 10px solid #06142f;
  width: 0;
  height: 0;
  position: absolute;
  top: 30px;
  left: -9px;
}

.background-years > div:nth-child(odd) > div + div, .background-years > div:nth-child(odd) > div > h2 {
  background-color: #f2a20c;
}

.background-years > div:nth-child(2n) > div + div, .background-years > div:nth-child(2n) > div > h2 {
  background-color: #06142f;
}

@media (width <= 1024px) {
  .background-years {
    background-position: 10% 0;
    background-size: 10px 85%;
  }

  .background-years > div > div + div {
    width: 70px;
    height: 70px;
    font-size: 1.5rem;
    left: 11%;
  }

  .background-years > div > div > h2:after {
    left: -18px;
    right: initial;
    top: 21px !important;
  }

  .background-years > div:nth-child(odd) > div > h2:after {
    border-left: 10px solid #0000;
    border-right: 10px solid #f2a20c;
  }
}

.main {
  perspective: .5px;
  height: 100vh;
  overflow: hidden auto;
}

.section {
  transform-style: preserve-3d;
  color: #fff;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.no-parallax {
  z-index: 999;
  background-color: #111;
}

section h1 {
  text-align: center;
  font-family: sans-serif;
  font-size: 4rem;
}

.parallax h1 {
  width: 60%;
  font-size: 2rem;
}

.parallax:after {
  content: " ";
  z-index: -1;
  background-size: 100%;
  position: absolute;
  inset: 0;
  transform: translateZ(-1px)scale(1.5);
}

.bg:after {
  background: url("https://picsum.photos/1080/720") 0 0 / cover;
}
/*# sourceMappingURL=index.35d5701e.css.map */
